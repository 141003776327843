import React from "react";

const ProductItem = ({ product, cat }) => {
  return (
    <div className="p-4 border rounded-lg shadow-md hover:shadow-alm-gray">
      <img
        className="h-auto max-w-full rounded-lg "
        src={require(`../../assets/images/products/${cat}/${product.image}`)}
        alt=""
      />
    </div>
  );
};

export default ProductItem;
