import React from "react";

export function AlmAbout() {
  return (
    <div className="container mx-auto py-12">
      <h1 className="font-custom1 text-center text-3xl font-bold mb-4 text-alm-red">
        About Us
      </h1>
      <div className="grid grid-cols-3 md:grid-cols-3 gap-4">
        <div className=" col-span-1">
          <img
            src={require(`../../assets/images/presentation/about.jpg`)}
            alt="About Us"
            className="h-auto max-w-auto max-h-auto rounded-lg"
          />
        </div>
        <div className=" col-span-2">
          <p className="font-custom1 text-md mb-4 text-alm-gray">
            Welcome to our company! We are a team of passionate professionals
            dedicated to providing high-quality products and services to our
            customers.
          </p>
          <p className="font-custom1 text-md mb-4 text-alm-gray">
            Our mission is to create innovative solutions that make a positive
            impact on the lives of our clients. We believe in the power of
            teamwork, open communication, and continuous learning.
          </p>
          <p className="font-custom1 text-md mb-4 text-alm-gray my-2">
            With years of industry experience, we have the expertise and
            resources to tackle even the most complex challenges. Our commitment
            to excellence is reflected in every aspect of our work.
          </p>
          <a
            href="/"
            className="font-custom1 bg-alm-red text-white px-2 py-2 rounded-lg hover:bg-alm-gray"
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
}
