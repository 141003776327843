import {
  Footer,
  FooterBrand,
  FooterCopyright,
  FooterDivider,
  FooterIcon,
  FooterLink,
  FooterLinkGroup,
  FooterTitle,
} from "flowbite-react";
import {
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsFillTelephoneFill,
  BsEnvelope,
  BsPinMapFill,
} from "react-icons/bs";

export function AlmFooter() {
  return (
    <Footer container>
      <div className="w-full">
        <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
          <div>
            <FooterBrand
              className="h-mr-8 h-8 sm:h-8 sm:w-32"
              href="https://www.almanti.com"
              src="../favicon.ico"
              alt="Almanti Logo"
            />
            <br></br>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              <BsFillTelephoneFill />
              <a href="tel:+ 212668347977" className="hover:underline">
                + 212 668 347 977
              </a>
              <br></br>
              <a href="tel:+ 212668703751" className="hover:underline">
                + 212 668 703 751
              </a>
            </p>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <BsEnvelope />
              <a
                href="mailto:almantisarl@outlook.com"
                className="hover:underline"
              >
                almantisarl@outlook.com
              </a>
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              <BsPinMapFill /> 10, Rue Liberté, 3ème étage, App 5,
              Casablanca-Anfa
            </p>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
            <div>
              <FooterTitle title="about" />
            </div>
            <div>
              <FooterTitle title="Products" />
              <FooterLinkGroup col>
                <FooterLink href="#">Category - 1</FooterLink>
                <FooterLink href="#">Category - 2</FooterLink>
              </FooterLinkGroup>
            </div>
            <div>
              <FooterTitle title="Services" />
              <FooterLinkGroup col>
                <FooterLink href="#">Export &amp; Import</FooterLink>
                <FooterLink href="#">Distribution</FooterLink>
              </FooterLinkGroup>
            </div>
          </div>
        </div>
        <FooterDivider />
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <FooterCopyright href="#" by="Almanti" year={2024} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <FooterIcon href="#" icon={BsFacebook} />
            <FooterIcon href="#" icon={BsInstagram} />
            <FooterIcon href="#" icon={BsTwitter} />
          </div>
        </div>
      </div>
    </Footer>
  );
}
