import { Carousel } from "flowbite-react";

export function AlmCarousel() {
  return (
    <div className="h-96 sm:h-80 xl:h-80 2xl:h-96 mx-8 mt-4">
      <Carousel>
        <img
          className="h-full w-full object-cover"
          src={require(`../../assets/images/presentation/p1.jpg`)}
          alt="p1"
        />
        <img
          className="h-full w-full object-cover"
          src={require(`../../assets/images/presentation/p2.jpg`)}
          alt="p2"
        />
        {/* <img
          className="h-96 w-96 rounded-full object-cover object-center"
          src={require(`../../assets/images/presentation/p3.jpg`)}
          alt="p3"
        /> */}
        <img
          className="h-full w-full object-cover"
          src={require(`../../assets/images/presentation/p4.jpg`)}
          alt="p4"
        />
        <img
          className="h-full w-full object-cover"
          src={require(`../../assets/images/presentation/p5.jpg`)}
          alt="p5"
        />
      </Carousel>
    </div>
  );
}
