import React from "react";
import { AlmNavbar } from "./components/Navbar/AlmNavbar";
import { AlmCarousel } from "./components/Carousel/AlmCarousel";
import { AlmFooter } from "./components/Footer/AlmFooter";
import { ProductList } from "./components/ProductList/ProductList";
import { AlmAbout } from "./components/About/AlmAbout";
// import { ProductPage } from "./components/Product/ProductPage";

function App() {
  return (
    <>
      <AlmNavbar />
      <AlmCarousel />
      <ProductList />
      {/* <ProductPage /> */}
      <AlmAbout />
      <AlmFooter />
    </>
  );
}

export default App;
