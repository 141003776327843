import React, { useState, useEffect } from "react";
import { ProductService } from "../../services/ProductService";
import ProductItem from "./ProductItem";
import { BsTools } from "react-icons/bs";
import { IoHardwareChipOutline } from "react-icons/io5";
export function ProductList() {
  /* const [products, setProducts] = useState([]);

  useEffect(() => {
    ProductService.getProducts("tools").then((data) =>
      setProducts(data.slice(0, 12))
    );
  }, []); */
  const [tools, setTools] = useState([]);
  const [hardware, setHardware] = useState([]);

  useEffect(() => {
    ProductService.getProducts("tools").then((data) =>
      setTools(data.slice(0, 12))
    );
  }, []);

  useEffect(() => {
    ProductService.getProducts("hardware").then((data) =>
      setHardware(data.slice(0, 12))
    );
  }, []);

  return (
    <div className=" m-8">
      <h1 className="font-custom1 text-center text-3xl font-bold my-4 text-alm-red">
        Product List
      </h1>

      <h1 className="font-custom1 flex justify-between  text-2xl font-bold m-4 text-alm-gray">
        <IoHardwareChipOutline />
        HARDWARE
        <IoHardwareChipOutline />
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 ">
        {hardware.map((product) => (
          <ProductItem key={product.id} product={product} cat="hardware" />
        ))}
      </div>
      <h1 className="font-custom1 flex justify-between text-2xl font-bold m-8 text-alm-gray">
        <BsTools />
        TOOLS
        <BsTools />
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 ">
        {tools.map((product) => (
          <ProductItem key={product.id} product={product} cat="tools" />
        ))}
      </div>
    </div>
  );
}
