import products from "../assets/data/products.json";

export const ProductService = {
  getProductsData(cat) {
    if (cat === "tools") {
      return products.data.tools;
    } else {
      if (cat === "hardware") {
        return products.data.hardware;
      }
    }
  },

  getProductsByCat(cat) {
    return products.data.cat;
  },
  getProductsMini3() {
    return Promise.resolve(this.getProductsData().slice(0, 3));
  },

  getProductsMini() {
    return Promise.resolve(this.getProductsData().slice(0, 6));
  },

  getProductsSmall() {
    return Promise.resolve(this.getProductsData().slice(0, 10));
  },

  getProducts(cat) {
    return Promise.resolve(this.getProductsData(cat));
  },

  getProductsWithOrdersSmall() {
    return Promise.resolve(this.getProductsWithOrdersData().slice(0, 10));
  },

  getProductsWithOrders() {
    return Promise.resolve(this.getProductsWithOrdersData());
  },
};
